<template>
  <b>
    {{stat}}
  </b>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true
    },
    menuID: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    timeType: {
      type: String,
      required: true
    },
  },
  data: () => ({
    stat: null,
  }),
  watch: {
    workspaceID () {
      this.handleGetData()
    },
    menuID () {
      this.handleGetData()
    },
    type () {
      this.handleGetData()
    },
    timeType () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      api.getAllWithoutLimit ('turismo', `v1/public/stats/${this.workspaceID}/${this.menuID}/${this.type}/${this.timeType}`)
        .then(response => {
          this.stat = response.result
        })
        .catch(error => {
          console.log(error)
          this.stat = ''
        })
    },
  }
}
</script>

