<template>
  <v-card class="mx-auto">
    <v-app-bar
      color="primary"
      elevation="0"
    >
      <!--v-app-bar-nav-icon></v-app-bar-nav-icon-->

      <v-toolbar-title style="color:white;">Accesos</v-toolbar-title>

      <v-spacer/>
      <v-btn 
        color="primary"
        @click="handleRefresh"
        style="margin-right:10px;"
      >
      <v-icon>mdi-refresh</v-icon>
        actualizar
      </v-btn>
      <v-select
        :items="types"
        v-model="type"
        solo
        hide-details
        dense
        item-text="name"
        item-value="id"
        style="margin-right:10px;"
      />
      <v-select
        :items="timeTypes"
        v-model="timeType"
        hide-details
        dense
        solo
        item-text="name"
        item-value="id"
      />
      <!--v-btn icon> nt
        <v-icon>mdi-magnify</v-icon>
      </v-btn-->
    </v-app-bar>

    <v-container style="max-width:100% !important">
      <v-row dense>
        <v-col cols="12">
          <expandable-item v-if="type && timeType" :id="main" :workspaceID="workspaceID" :type="type" :timeType="timeType" :forceRefresh="forceRefresh" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import ExpandableItem from './ExpandableItem'
export default {
  components: {
    ExpandableItem,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    timeType: 'all',
    type: 'desktop',
    forceRefresh: null,
  }),
  computed: {
    main ()  {
      return 'aa5dd237-9e2a-401c-8b7c-0866e9486428'
    },
    types () {
      return [
        { id: 'desktop', name: 'TABLET'},
        { id: 'totem', name: 'TOTEM'},
        { id: 'mobile', name: 'M�VILES'},
      ]
    },
    timeTypes () {
      const aux = [{id: 'all', name: 'TODOS'}]
      for (let i = 0 ; i <= 12 ; i++) {
        let date = new Date()
        date = new Date(date.setMonth(date.getMonth() - i));
        aux.push({
          id: `${(date.getUTCMonth() + 1)}-${date.getUTCFullYear()}`,
          name: `${(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`
        })
      }
      return aux
    },
  },
  watch: {
    workspaceID () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    handleRefresh () {
      this.forceRefresh = new Date().toISOString()
    },
  },
}
</script>


