<template>
  <v-expansion-panels
    class="turismo"
  >
    <v-expansion-panel
      v-for="item of menus"
      :title="item.Title.es"
      :key="item.ID"
    >
      <v-expansion-panel-header>
        <v-layout wrap>
          <v-flex xs10>
            {{item.Title.es}}
          </v-flex>
          <v-flex xs2 style="text-aling: right;">
            <stat-item :workspaceID="workspaceID" :menuID="item.ID" :type="type" :timeType="timeType" />
          </v-flex>
        </v-layout>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="padding: 0 !important;">
        <expandable-item :id="item.ID" :workspaceID="workspaceID" :type="type" :timeType="timeType"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import api from '@/services/api'
import ExpandableItem from './ExpandableItem'
import StatItem from './StatItem'
export default {
  name: 'ExpandableItem',
  components: {
    ExpandableItem,
    StatItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    timeType: {
      type: String,
      required: true,
    },
    forceRefresh: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    menus: [],
  }),
  watch: {
    forceRefresh () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      api.getAllWithoutLimit ('turismo', `v1/public/turismo-local/${this.id}/childrens`)
        .then(response => {
          this.menus = response.map(item => {
            item.HideWorkspaces = item.HideWorkspaces ? JSON.parse(item.HideWorkspaces) : []
            const aux = item.HideWorkspaces && item.HideWorkspaces.filter(x => x.WorkspaceID === this.workspaceID).shift()
            item.HideWorkspace = aux && aux.Hide === true ? true : false
            item.Title = item.Title ? JSON.parse(item.Title) : null
            item.Description = item.Description ? JSON.parse(item.Description) : null
            item.Service = item.Service ? JSON.parse(item.Service) : null
            return item
          }).filter(x => (!x.MenuCheckWorkspaces || x.MenuCheckWorkspaces.indexOf(this.workspaceID) === -1) && ((!x.Hide && !x.HideWorkspace) || this.showAll))
        })
    },
  },
}
</script>
<style>
.turismo .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

